import * as React from "react"
import { Button, Col, Container, Row } from "react-bootstrap";
import HeaderImage from "../images/header-himchistka.jpg";
import HeaderImageWebp from "../images/header-himchistka.jpg.webp";
import BgImage from "../images/bg-himchistka.jpg";
import BgImageWebp from "../images/bg-himchistka.jpg.webp";
import OgImage from "../images/og-image-himchistka.jpg";
import HeaderJumbotron from "../components/HeaderJumbotron";
import withLayout from "../hoc/withLayout";
import HimchistkaImage01 from "../images/himchistka-01.jpg";
import HimchistkaImage01Webp from "../images/himchistka-01.jpg.webp";
import HimchistkaImage02 from "../images/himchistka-02.jpg";
import HimchistkaImage02Webp from "../images/himchistka-02.jpg.webp";
import HimchistkaImage03 from "../images/himchistka-03.jpg";
import HimchistkaImage03Webp from "../images/himchistka-03.jpg.webp";
import HimchistkaImage04 from "../images/himchistka-04.jpg";
import HimchistkaImage04Webp from "../images/himchistka-04.jpg.webp";
import HimchistkaImage05 from "../images/himchistka-05.jpg";
import HimchistkaImage05Webp from "../images/himchistka-05.jpg.webp";
import HimchistkaImage06 from "../images/himchistka-06.jpg";
import HimchistkaImage06Webp from "../images/himchistka-06.jpg.webp";
import HimchistkaImage07 from "../images/himchistka-07.jpg";
import HimchistkaImage07Webp from "../images/himchistka-07.jpg.webp";
import HimchistkaImage08 from "../images/himchistka-08.jpg";
import HimchistkaImage08Webp from "../images/himchistka-08.jpg.webp";
import HimchistkaImage09 from "../images/himchistka-09.jpg";
import HimchistkaImage09Webp from "../images/himchistka-09.jpg.webp";
import ModalFormCallOrder from "../components/ModalFormCallOrder";
import Yandex from "../components/Yandex";
import Footer from "../components/Footer";
import { Gallery } from "../components/Gallery";
import { useStaticQuery, graphql } from "gatsby";

import "./himchistka.scss";

const price = [
  {
    title: "Химчистка салона",
    price: "от 400 BYN",
    img: HimchistkaImage01,
    imgWebp: HimchistkaImage01Webp,
    footnote: '',
  },
  {
    title: "Химчистка потолка",
    price: "от 50 BYN",
    img: HimchistkaImage02,
    imgWebp: HimchistkaImage02Webp,
    footnote: '',
  },
  {
    title: "Химчистка передней панели",
    price: "от 40 BYN",
    img: HimchistkaImage03,
    imgWebp: HimchistkaImage03Webp,
    footnote: '',
  },
  {
    title: "Химчистка дверей изнутри",
    price: "от 40 BYN",
    img: HimchistkaImage04,
    imgWebp: HimchistkaImage04Webp,
    footnote: '',
  },
  {
    title: "Химчистка сидений",
    price: "от 40 BYN",
    img: HimchistkaImage05,
    imgWebp: HimchistkaImage05Webp,
    footnote: '* Стоимость за 1 сиденье',
  },
  {
    title: "Химчистка напольного покрытия",
    price: "от 50 BYN",
    img: HimchistkaImage06,
    imgWebp: HimchistkaImage06Webp,
    footnote: '',
  },
  {
    title: "Химчистка багажного отделения",
    price: "от 40 BYN",
    img: HimchistkaImage07,
    imgWebp: HimchistkaImage07Webp,
    footnote: '',
  },
  {
    title: "Химчистка ворсовых ковров",
    price: "от 25 BYN",
    img: HimchistkaImage08,
    imgWebp: HimchistkaImage08Webp,
    footnote: '* Стоимость за 4 ковра или за 1 коврик в багажнике',
  },
  {
    title: "Кондиционер для кожи",
    price: "20 - 50 BYN",
    img: HimchistkaImage09,
    imgWebp: HimchistkaImage09Webp,
    footnote: '',
  },
];


const HimchistkaPage = () => {
  const gallery = useStaticQuery(graphql`
  {
    allFile(
      filter: {extension: {in: ["jpg", "webp"]}, dir: {regex: "/\/src\/images\/himchistka$/"}}
      sort: {fields: base, order: ASC}
    ) {
      edges {
        node {
          base
          publicURL
        }
      }
    }
  }
`)

  return (
    <>
      <HeaderJumbotron 
        h1="Химчистка автомобиля в Минске" 
        h1Sub="" 
        bgImg={HeaderImage} bgImgWebp={HeaderImageWebp}
        bgImgAlt="химчистка автомобиля"
        withPhoneButton
        modalSubject="Химчистка автомобиля - заказ звонка"
        modalTitle="Химчистка автомобиля - заказ звонка"
      />
      <main>
        <div className="himchistka-about">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="text-wrap">
                  <p>
                    Химчистка автомобиля - это процесс глубокой очистки всех поверхностей от 
                    различных загрязнений, пятен и пыли при помощи специальных моющих средств и инструментов.
                  </p>
                  <p>
                    Химчистка позволяет сохранить салон свежим, приятным и ухоженным!
                  </p>
                </div>
              </Col>

              <Col xs={12} md={6}>
                <picture className="pic">
                  <source srcSet={BgImageWebp} type="image/webp" />
                  <img src={BgImage} alt="химчистка автомобиля" />
                </picture>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="himchistka-price mb-5">
          <Container>
            <Row>
              {
                price.map(item => (
                  <Col xs={12} md={6} lg={4} className="himchistka-price__item">
                    <picture>
                      <source srcSet={item.imgWebp} type="image/webp" />
                      <img src={item.img} alt={item.title} />
                    </picture>

                    <div className="himchistka-price__text">
                      <h3>{item.title}</h3>
                      <p className="himchistka-price__price">{item.price}</p>
                      <ModalFormCallOrder subject={item.title} title={item.title}>
                        <Button variant={'secondary'} className="mb-4">
                          Заказать
                        </Button>
                      </ModalFormCallOrder>
                      {
                        <em>{item.footnote || ' '}</em>
                      }
                    </div>
                  </Col>
                ))
              }
            </Row>
          </Container>
        </div>

        <Container className="mb-5">
          <Gallery images={gallery.allFile.edges.map(({node}) => node.publicURL)} />
        </Container>

        <div>
          <Container>
            <p>
              В современном мире водители как правило загружены делами и им некогда самостоятельно приводить в порядок свой автомобиль. 
              Именно поэтому всё больше и больше людей обращаются к профессионалам. 
            </p>

            <p>
              Autozorgo Detailing — химчистка салона в Минске по доступным ценам. Мы осуществляем как полную химчистку, так и частичную. 
              В частичную химчистку входят такие услуги, как: химчистка потолка, химчистка передней панели, химчистка дверей изнутри, 
              химчистка сидений, химчистка напольного покрытия, химчистка багажного отделения, а также химчистка ворсовых ковров.
            </p>

            <p>
              Каждый день в салон попадают пыль, песок и другого рода загрязнения. 
              Поэтому очень важно регулярно производить химчистку или стараться поддерживать порядок в салоне машины. 
              Если сильно загрязнить интерьер автомобиля, то появляется риск возникновения бактерий, провоцирующих аллергию. 
              Наши высококвалифицированные специалисты используют в работе только сертифицированную автокосметику, 
              которая справляется даже с трудно выводимыми пятнами, а также не повреждает элементы салона и не оставляет неприятных запахов.
            </p>

            <p>
              Обратившись к нам за химчисткой, вы не только подарите своему салону обновленный вид, 
              но и получите качественный сервис по доступной цене.
            </p>
          </Container>
        </div>

        <Yandex/>
      </main>
      <Footer/>
    </>
  );
};

export default withLayout(HimchistkaPage, 
  "Заказать химчистку автомобиля в Минске - студия детейлинга AutoZorgo",
  "Глубокая очистка всех поверхностей от различных загрязнений, пятен и пыли при помощи специальных моющих средств и инструментов",
  OgImage
)